import axios from "axios";
import { useState } from "react";
import { Navbar } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
const api = process.env.REACT_APP_API;
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const login = () => {
    axios
      .post(`${api}/users/login`, {
        email: email,
        password: pass,
      })
      .then((res) => {
        window.localStorage.setItem("userData", res);
        navigate("/home");
      });
    console.log(email, pass);
  };
  return (
    <>
      <Navbar />
      <div className="login_container">
        <h1>Log In</h1>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter email"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              onChange={(e) => setPass(e.target.value)}
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <input
            type="button"
            value="Submit"
            className="submit_btn"
            onClick={login}
          />
        </Form>
      </div>
    </>
  );
};

export default Login;
