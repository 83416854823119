import "./App.css";
import "./style.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Signup from "./components/Signup";
import Login from "./components/Login";

function App() {
  return (
    <div className="App">
      <main>
        <Router>
          {/* <Navbar /> */}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/" element={<Home />} exact>
              {/* <Route path="/home" element={<Home />} /> */}
            </Route>
          </Routes>
        </Router>
      </main>
    </div>
  );
}

export default App;
