import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
function Layout() {
  // useEffect(() => {
  //   if (!window.localStorage.getItem("userData")) {
  //     window.location.replace("/login");
  //   }
  // }, []);

  // if (!window.localStorage.getItem("userData")) return "";

  return (
    <div className="main_div">
      <Outlet />
    </div>
  );
}

export default Layout;
