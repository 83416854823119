import axios from "axios";
import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { useNavigate } from "react-router-dom";
const api = process.env.REACT_APP_API;
const Signup = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [clientId, setClientId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submit = () => {
    console.log(fname, lname, email, pass, aadhar);
    axios
      .post(`${api}/users/register`, {
        first_name: fname,
        last_name: lname,
        email: email,
        password: pass,
        aadhar: aadhar,
      })
      .then((res) => {
        setClientId(res.data.client_id);
        handleClose();
        setOtpSent(true);
      });
  };
  const handleSignUp = () => {
    console.log(otp);
    axios
      .post(`${api}/users/aadhar_otp_verify`, {
        otp: otp.toString(),
        email: email,
        client_id: clientId,
      })
      .then((res) => {
        navigate("/login");
      });
  };
  return (
    <>
      <Navbar />
      <div className="signup_container">
        <h1>Sign Up</h1>
        {!otpSent ? (
          <Form>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Control
                onChange={(e) => setFname(e.target.value)}
                type="text"
                placeholder="Enter First Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Control
                onChange={(e) => setLname(e.target.value)}
                type="text"
                placeholder="Enter Last Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicNumber">
              <Form.Control
                onChange={(e) => setAadhar(e.target.value)}
                type="tel"
                minLength="12"
                maxLength="12"
                placeholder="Enter Your Aadhar"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                onChange={(e) => setPass(e.target.value)}
                type="password"
                placeholder="Password"
              />
            </Form.Group>
            <input
              type="button"
              value="Submit"
              className="submit_btn"
              onClick={handleShow}
            />
          </Form>
        ) : (
          <Form>
            <Form.Group className="mb-3" controlId="formBasicOtp">
              <Form.Control
                onChange={(e) => setOtp(e.target.value)}
                type="tel"
                autoComplete="false"
                placeholder="Enter OTP"
              />
            </Form.Group>
            <input
              type="button"
              value="Sign Up"
              className="submit_btn"
              onClick={handleSignUp}
            />
          </Form>
        )}

        {/* Popup */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Aggreement</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={submit}>
              Agree & Get Aadhar OTP
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Signup;
